<template>
  <div v-if="user" class="relative inline-flex">
    <button ref="trigger" class="inline-flex justify-center items-center group" aria-haspopup="true"
            @click.prevent="toggleDropdown" :aria-expanded="dropdownOpen">
      <div class="flex items-center justify-center">
        <img v-if="$page.props.jetstream.managesProfilePhotos" :src="user.profile_photo_url"
             :alt="`${user.last_name} ${user.name} profile picture`" class="rounded-full w-12 h-12 object-cover"/>
        <span v-else class="inline-flex rounded-md">
          <Profile/>
          <button type="button"
                  class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none focus:bg-gray-50 active:bg-gray-50 transition ease-in-out duration-150">
              {{ $page.props.auth.user.name }}
              <svg class="ms-2 -me-0.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                   fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
              </svg>
          </button>
        </span>
      </div>

      <div class="flex items-center truncate">
        <svg class="h-2 aspect-square shrink-0 ml-2 fill-current text-black transition-transform"
             :class="{ 'rotate-180': dropdownOpen }" viewBox="0 0 12 12" v-if="!hideDropdown">
          <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z"/>
        </svg>
      </div>
    </button>
    <transition enter-active-class="transition ease-out duration-200 transform"
                enter-from-class="opacity-0 -translate-y-2" enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-out duration-200" leave-from-class="opacity-100"
                leave-to-class="opacity-0">
      <div v-show="dropdownOpen"
           class="z-50 origin-top-right absolute top-full w-64 bg-white border border-grey-50 p-5 rounded-xl shadow-lg mt-1 divide-zinc-100 divide-y overflow-visible"
           :class="align === 'right' ? 'right-0' : 'left-0'">
        <div class="flex flex-row gap-2 mt-1 mb-2">
          <div class="">
            <div class="text-md font-bold text-gray-800">{{ user.last_name }} {{ user.name }}</div>
            <div class="text-sm font-semibold text-gray-700 mt-1">{{ $page.props.auth.user.current_team.name }}</div>
          </div>
        </div>
        <div ref="dropdown" @focusin="dropdownOpen = true" @focusout="dropdownOpen = false"
             class="divide-grey-200 divide-y z-50">
          <slot name="links">

            <Link class="w-full font-medium text-sm text-gray-700 hover:text-green-200 flex items-center pt-3 mb-3"
                  :href="route('profile.show')" @click="dropdownOpen = false" as="button">Mon Profil
            </Link>

            <!-- Team Settings -->
            <Link class="w-full font-medium text-sm text-gray-700 hover:text-green-200 flex items-center pt-3 mb-3"
                  :href="route('teams.show', $page.props.auth.user.current_team)" @click="dropdownOpen = false"
                  as="button">Mon compte Notariel
            </Link>

            <!-- Billing & Stats -->
            <Link class="w-full font-medium text-sm text-gray-700 hover:text-green-200 flex items-center pt-3 mb-3"
                  :href="route('statistics.show')" @click="dropdownOpen = false"
                  as="button">Statistiques & Facturation
            </Link>

            <DropdownLink v-if="$page.props.jetstream.hasApiFeatures" :href="route('api-tokens.index')">
              API Tokens
            </DropdownLink>

            <Link class="w-full font-medium text-sm text-gray-700 hover:text-green-200 flex items-center pt-3"
                  :href="route('logout')" method="post" @click="dropdownOpen = false" as="button">Déconnexion
            </Link>
          </slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import Profile from '@/Components/Client/Icons/Profile.vue';
import {Link, usePage} from '@inertiajs/vue3';
import {computed, onMounted, onUnmounted, ref} from 'vue';
import DropdownLink from "@/Components/DropdownLink.vue";

defineProps(['align', 'hideDropdown']);

defineEmits({
  click: null,
});

const dropdownOpen = ref(false)
const trigger = ref(null)
const dropdown = ref(null)
const user = computed(() => usePage().props?.auth?.user)

// close on click outside
const clickHandler = ({target}) => {
  if (!dropdownOpen.value || dropdown.value.contains(target) || trigger.value.contains(target)) return
  dropdownOpen.value = false
}
// close if the esc key is pressed
const keyHandler = ({keyCode}) => {
  if (!dropdownOpen.value || keyCode !== 27) return
  dropdownOpen.value = false
}
const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value
}
onMounted(() => {
  document.addEventListener('click', clickHandler)
  document.addEventListener('keydown', keyHandler)
})
onUnmounted(() => {
  document.removeEventListener('click', clickHandler)
  document.removeEventListener('keydown', keyHandler)
})


</script>
