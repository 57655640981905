<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.582" height="20" viewBox="0 0 17.582 20">
    <g id="noun_notification_2184960" transform="translate(-25.5 -60.523)">
      <g id="Groupe_7105" data-name="Groupe 7105" transform="translate(26 61.023)">
        <g id="Groupe_7104" data-name="Groupe 7104" transform="translate(0 0)">
          <path id="Tracé_7743" data-name="Tracé 7743"
                d="M32.736,24.769a6.219,6.219,0,0,0-4.664,6.022v4.145a.692.692,0,0,1-.69.692,1.382,1.382,0,1,0,0,2.764H41.2a1.382,1.382,0,1,0,0-2.764.689.689,0,0,1-.69-.692V30.791a6.22,6.22,0,0,0-4.664-6.022v-.714a1.555,1.555,0,1,0-3.109,0Zm-.864,14.313h4.836a2.418,2.418,0,0,1-4.836,0Z"
                transform="translate(-26 -22.5)" fill="none" stroke="#27272a" stroke-width="1"
                fill-rule="evenodd"></path>
        </g>
      </g>
    </g>
  </svg>
</template>
  