<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22.363" height="24.401"
       viewBox="0 0 22.363 24.401">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_2011" data-name="Rectangle 2011" width="22.363" height="24.401" fill="#1eac9c"></rect>
      </clipPath>
    </defs>
    <g id="Groupe_8077" data-name="Groupe 8077" transform="translate(0 0)">
      <g id="Groupe_8076" data-name="Groupe 8076" transform="translate(0 0)" clip-path="url(#clip-path)">
        <path id="Tracé_9050" data-name="Tracé 9050"
              d="M21.8,37.1a.666.666,0,0,1-.658-.651,9.914,9.914,0,0,0-19.827,0,.666.666,0,0,1-.658.651A.666.666,0,0,1,0,36.451,11.118,11.118,0,0,1,11.179,25.4a11.118,11.118,0,0,1,11.178,11.05.557.557,0,0,1-.557.651"
              transform="translate(0 -12.7)" fill="#1eac9c"></path>
        <path id="Tracé_9051" data-name="Tracé 9051"
              d="M17.3,10.2A5.151,5.151,0,0,1,12.14,5.1a5.159,5.159,0,0,1,10.318,0A5.151,5.151,0,0,1,17.3,10.2m0-8.95A3.907,3.907,0,0,0,13.4,5.1a3.9,3.9,0,0,0,7.79,0A3.907,3.907,0,0,0,17.3,1.25"
              transform="translate(-6.07 0)" fill="#1eac9c"></path>
      </g>
    </g>
  </svg>
</template>