<template>
  <div class="max-w-7xl mx-auto">
    <nav class="flex items-center justify-between p-4 md:p-8" id="navbar-default">
      <!-- Logo à gauche -->
      <div class="flex-shrink-0">
        <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse">
          <ApplicationLogo/>
        </a>
      </div>

      <!-- Zone centrale flexible (SearchInput sur desktop) -->
      <div class="hidden md:flex flex-grow justify-center mx-4">
        <SearchInput v-if="user" class="w-full max-w-xl"/>
      </div>

      <!-- Zone de droite (boutons de connexion ou menu utilisateur) -->
      <div class="flex items-center">
        <template v-if="user">
          <!-- Contenu pour utilisateur connecté -->
          <Link
              class="hidden md:flex font-medium text-sm text-black hover:text-green-200 items-center whitespace-pre mr-4"
              :href="route('projects.lists')"
              method="get"
          >
            Mes dossiers
          </Link>
          <DropdownNotification align="right" class="mr-3"/>
          <DropdownProfile
              align="right"
              @click="$emit('toggleNavigationDropdown')"
          />
        </template>
        <template v-else>
          <!-- Boutons pour utilisateur non connecté -->
          <div class="flex items-center flex-col sm:flex-row">
            <Link
                class="font-medium text-sm text-green-100 hover:text-green-200 flex items-center cursor-pointer mb-2 sm:mb-0 sm:mr-5 w-full sm:w-auto justify-center"
                :href="route('register')"
            >
              S'inscrire
            </Link>
            <Link
                class="transition-colors rounded-full px-4 py-2 text-sm font-medium bg-green-100 hover:bg-green-200 text-white w-full sm:w-auto flex items-center justify-center"
                :href="route('login')"
            >
              Connexion
            </Link>
          </div>
        </template>
      </div>
    </nav>
    <!-- Menu mobile -->

    <div class="w-full mb-2 md:hidden p-5">
      <SearchInput v-if="user" class="w-full"/>
    </div>

    <div
        v-if="showingNavigationDropdown"
        class="md:hidden"
    >
      <div class="pt-2 pb-3 space-y-1">
        <ResponsiveNavLink v-if="user" :href="route('projects.lists')" :active="route().current('projects.lists')">
          Mes dossiers
        </ResponsiveNavLink>
        <!-- Ajoutez d'autres liens de navigation mobile ici si nécessaire -->
      </div>

      <!-- Options de profil pour mobile -->
      <div class="pt-4 pb-1 border-t border-gray-200">
        <div v-if="user" class="flex items-center px-4">
          <div class="font-medium text-base text-gray-800">{{ user.name }}</div>
          <div class="font-medium text-sm text-gray-500">{{ user.email }}</div>
        </div>

        <div class="mt-3 space-y-1">
          <!-- Ajoutez ici les options de profil pour mobile -->
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import DropdownNotification from '@/Components/Client/Dropdowns/DropdownNotification.vue';
import DropdownProfile from '@/Components/Client/Dropdowns/DropdownProfile.vue';
import SearchInput from '@/Components/Client/Inputs/SearchInput.vue';
import {Link, usePage} from '@inertiajs/vue3';
import {computed} from 'vue';
import ResponsiveNavLink from "@/Components/ResponsiveNavLink.vue";

const user = computed(() => usePage().props?.auth?.user)
const props = defineProps({
  showingNavigationDropdown: Boolean
});

const emit = defineEmits(['toggleNavigationDropdown']);

</script>