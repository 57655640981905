<template>
  <div
      class="flex items-center border-grey-200 bg-grey-100 rounded-full border-2 focus:border-green-100 focus:ring-0 focus:outline-none pr-1">
    <input type="search"
           class="grow border-none bg-transparent focus:ring-0 text-sm font-light"
           v-model="searchValue"
           placeholder="Rechercher un dossier"
           @keyup.enter="searchProject">
    <button class="flex items-center justify-center bg-green-100 rounded-full w-8 h-8" @click="searchProject">
      <Search class="w-5 h-5"></Search>
    </button>
  </div>
</template>

<script setup>
import Search from '@/Components/Client/Icons/Search.vue';
import Input from '@/Components/Client/Inputs/Input.vue';

import {ref, watch} from 'vue';
import {router} from '@inertiajs/vue3';

// Initialize search value with the query string search value
let urlParams = new URLSearchParams(window.location.search);
const search = urlParams.get('search');
const searchValue = ref(search ?? '');

// Observe search value changes and if it's empty, clear the search query string
watch(() => searchValue.value, (value) => {
  if (!value) {
    router.visit(route('projects.lists'));
  }
});

const searchProject = () => {
  if (!searchValue.value) return;
  router.visit(route('projects.lists', {search: searchValue.value}), {preserveState: true});
};
</script>
